import {
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
  DialogActions,
  Button,
  Stack,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';

interface IVehiclePerimeterLinkProps {
  vhc_id: string;
  setOpenDialog: (open: boolean) => void;
}

export const VehiclePerimeterLink: React.FC<IVehiclePerimeterLinkProps> = ({
  vhc_id,
  setOpenDialog,
}) => {
  const queryClient = useQueryClient();
  const [geoIds, setGeoIds] = React.useState<string[]>([]);
  const touchwayApi = useTouchwayApi();

  // Query to fetch all available geofences
  const geofencesQuery = useQuery({
    queryKey: queryKeys.geofences.geofenceControllerFindAll(),
    queryFn: () => touchwayApi.api.geofences.geofenceControllerFindAll(),
  });

  // Query to fetch geofences linked to the specific vehicle
  const vehicleLinkedGeofencesQuery = useQuery({
    queryKey: queryKeys.vehicles.vehicleControllerListLinkedGeofences({
      vhc_id,
    }),
    queryFn: () =>
      touchwayApi.api.vehicles.vehicleControllerListLinkedGeofences({
        vhc_id,
      }),
  });

  useEffect(
    () =>
      setGeoIds(
        vehicleLinkedGeofencesQuery.data?.map((geofence) => geofence.geo_id) ||
          [],
      ),
    [vehicleLinkedGeofencesQuery.data],
  );

  const updateLinkMutation = useMutation(
    touchwayApi.api.vehicles.vehicleControllerLinkVehicleToGeofences,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeys.vehicles.vehicleControllerListLinkedGeofences({ vhc_id }),
        );
        setOpenDialog(false);
        toast.success('Vinculado com sucesso');
      },
      onError: () => {
        toast.error('Erro ao vincular');
      },
    },
  );

  // Handle loading states for both queries
  if (geofencesQuery.isLoading || vehicleLinkedGeofencesQuery.isLoading) {
    return <CircularProgress />;
  }

  // Handle error states for both queries
  if (geofencesQuery.isError || vehicleLinkedGeofencesQuery.isError) {
    return <div>Error loading data</div>;
  }

  const handleCheckboxChange = (geo_id: string) => {
    setGeoIds((prevGeoIds) =>
      prevGeoIds.includes(geo_id)
        ? prevGeoIds.filter((id) => id !== geo_id)
        : [...prevGeoIds, geo_id],
    );
  };

  const handleSelectAll = () => {
    if (geoIds.length === geofencesQuery.data?.length) {
      // Se todos estiverem selecionados, desmarcar todos
      setGeoIds([]);
    } else {
      // Caso contrário, marcar todos
      setGeoIds(geofencesQuery.data?.map((geofence) => geofence.geo_id) || []);
    }
  };

  function handleClose() {
    setOpenDialog(false);
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="right"
        justifyContent="right"
        position="absolute"
        right="10px"
      >
        <Button onClick={handleSelectAll} color="secondary">
          {geoIds.length === geofencesQuery.data?.length
            ? 'Desmarcar Tudo'
            : 'Marcar Tudo'}
        </Button>
      </Stack>
      <FormGroup>
        {geofencesQuery.data?.map((geofence) => (
          <FormControlLabel
            key={geofence.geo_id}
            control={
              <Checkbox
                checked={geoIds.includes(geofence.geo_id)}
                onChange={() => handleCheckboxChange(geofence.geo_id)}
              />
            }
            label={geofence.geo_name}
          />
        ))}
      </FormGroup>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          disabled={updateLinkMutation.isLoading}
        >
          Cancelar
        </Button>
        <LoadingButton
          onClick={() =>
            updateLinkMutation.mutate({
              geofence_ids: geoIds ?? [],
              vhc_id,
            })
          }
          loading={updateLinkMutation.isLoading}
          color="primary"
        >
          Vincular
        </LoadingButton>
      </DialogActions>
    </>
  );
};
