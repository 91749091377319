import { FC, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Page } from 'src/components/layouts/Page';
import { useQuery } from '@tanstack/react-query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { queryKeys } from 'src/config/query';
import { firebaseRealtimeEnum } from 'src/config';
import useRealtime from 'src/hooks/firebase/useRealtime';
import {
  GseSocketRow,
  GseSocketRowHeader,
} from '../gses/components/GseSocketCard';
import { LocatorConverter } from './class/Locator';
import { ILocatorFirebaseRealtimeType } from './types/ILocatorFirebaseRealtime.type';
import { groupLocatorsByLastDate } from './utils/group-locators-by-last-date';
import { mergeLocators } from './utils/merge-locators';
import PulsingBadge from '../devices/components/PulsingBadge';

interface RealtimeResponse {
  [key: string]: ILocatorFirebaseRealtimeType;
}

export const LocatorsRealtime: FC = () => {
  const touchwayApi = useTouchwayApi();
  const [fbLocators, setFbLocators] = useState<LocatorConverter[]>([]);
  const [rawFbLocators, setRawFbLocators] = useState<RealtimeResponse>({});

  const locatorsQuery = useQuery({
    queryKey: queryKeys.locatorControllerList.get({
      with_relations: true,
      lct_imei: undefined,
      lct_cell_number: undefined,
    }),
    queryFn: () =>
      touchwayApi.api.locator.locatorControllerList({
        with_relations: true,
        lct_imei: null,
        lct_cell_number: null,
      }),
  });

  useRealtime<RealtimeResponse>({
    collection: firebaseRealtimeEnum.locators,
    key: '',
    enabled: fbLocators.length === 0,
    onValueChange: (value) => {
      setRawFbLocators(value);
      setFbLocators(
        Object.keys(value).map((key) => new LocatorConverter(value[key])),
      );
    },
  });

  const mergedArr = mergeLocators({
    database_locators: locatorsQuery.data?.locators || [],
    firebase_realtime_locators: rawFbLocators,
  });

  const GroupLocators = groupLocatorsByLastDate(mergedArr);

  return (
    <Page
      title="Localizadores em tempo real"
      query={undefined}
      disabledFetching
      buttons={[]}
    >
      <Grid container>
        {Object.values(GroupLocators).map((group) => (
          <Grid container spacing={2} order={group.order}>
            <Grid item xs={12} mt={2}>
              <Typography variant="h6">{group.title}</Typography>
            </Grid>
            {group.arr.map(({ fb_locator, locator, lastCommunication }) => (
              <Grid key={locator.lct_id} item lg={3} md={4} sm={4} xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="body1">
                      {locator?.vehicle?.vhc_name}
                    </Typography>
                    <Typography variant="body2">{locator.lct_imei}</Typography>
                    <Typography variant="caption">
                      {lastCommunication?.toLocaleString()}
                    </Typography>
                    {fb_locator && (
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Box mx={-2} my={2}>
                          <Divider style={{ borderStyle: 'dotted' }} />
                        </Box>

                        <GseSocketRowHeader label="Últimas Atualizações" />

                        <GseSocketRow
                          label="Localização"
                          endIcon={
                            <PulsingBadge
                              variant={fb_locator.geolocation_date_status}
                              badgeLabel={fb_locator.geolocation_date_formatted}
                            />
                          }
                        />
                        <GseSocketRow
                          label="Heartbeat"
                          endIcon={
                            <PulsingBadge
                              variant={fb_locator.heartbeat_date_status}
                              badgeLabel={fb_locator.heartbeat_date_formatted}
                            />
                          }
                        />
                        <GseSocketRow
                          label="Info"
                          endIcon={
                            <PulsingBadge
                              variant="success"
                              badgeLabel={fb_locator.info_date_formatted}
                            />
                          }
                        />
                        <GseSocketRow
                          label="Ligamento"
                          endIcon={
                            <PulsingBadge
                              variant={fb_locator.ignition_on_date_status}
                              badgeLabel={fb_locator.ignition_on_date_formatted}
                            />
                          }
                        />
                        <GseSocketRow
                          label="Desligamento"
                          endIcon={
                            <PulsingBadge
                              variant={fb_locator.ignition_off_date_status}
                              badgeLabel={
                                fb_locator.ignition_off_date_formatted
                              }
                            />
                          }
                        />
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

export default LocatorsRealtime;
