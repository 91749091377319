/* eslint-disable max-len */
import * as React from 'react';
import { Box, Stack, Button, useTheme, makeStyles } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { MarkerF, Polygon } from '@react-google-maps/api';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { CustomMaps } from 'src/components/CustomMaps';
import { IGeofence } from 'src/services/touchway-base-api';
import { isNaN } from 'lodash';
import { DEFAULT_MAPS } from 'src/config/maps-default';
import MapsMarker from 'src/pages/devices/MapsMarker';
import type { Vehicle } from './types';

const VIEW_STATE = {
  latitude: -23.007925408651538,
  longitude: -47.14056616755707,
  zoom: 11,
};

export interface FleetMapProps {
  currentVehicleId?: string;
  onVehicleSelect?: (vehicleId: string) => void;
  onGeofenceSelect?: (geofenceId: string) => void;
  currentGeofenceId?: string;
  vehicles?: Vehicle[];
  geofences: IGeofence[];
}

export function FleetMap({
  onVehicleSelect,
  currentGeofenceId,
  currentVehicleId,
  vehicles = [],
  geofences = [],
}: FleetMapProps): JSX.Element {
  const [layerVisibility, setLayerVisibility] = React.useState({
    geofences: true,
    vehicles: true,
  });
  const { api } = useTouchwayApi();
  const { palette } = useTheme();
  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  const handleRecenterVehicle = React.useCallback(() => {
    try {
      if (!map) {
        return;
      }

      const currentVehicle = vehicles.find(
        ({ vehicle }) => vehicle.vhc_id === currentVehicleId,
      );

      if (currentVehicle) {
        const latitude = Number(currentVehicle.fb.latitude);
        const longitude = Number(currentVehicle.fb.longitude);

        // Verifique se as coordenadas são válidas antes de tentar usá-las
        if (!isNaN(latitude) && !isNaN(longitude)) {
          const position = {
            lat: latitude,
            lng: longitude,
          };
          map.panTo(position);
          map.setZoom(17);
        } else {
          console.error(
            'Coordenadas de veículo inválidas:',
            latitude,
            longitude,
          );
        }
      } else {
        map.panTo({ lat: VIEW_STATE.latitude, lng: VIEW_STATE.longitude });
      }
    } catch (error) {
      console.error('Erro ao centralizar veículo:', error);
    }
  }, [map, vehicles, currentVehicleId]);

  React.useEffect(() => {
    handleRecenterVehicle();
  }, [currentVehicleId, handleRecenterVehicle]);

  const calculatePolygonCenter = React.useCallback(
    (coordinates: { lat: number; lng: number }[]) => {
      let latSum = 0;
      let lngSum = 0;
      coordinates.forEach((coordinate) => {
        latSum += coordinate.lat;
        lngSum += coordinate.lng;
      });
      const lat = latSum / coordinates.length;
      const lng = lngSum / coordinates.length;
      return { lat, lng };
    },
    [],
  );

  const handleRecenterGeofence = React.useCallback(() => {
    try {
      if (!map) {
        return;
      }

      const currentGeofence = geofences.find(
        (geofence) => geofence.geo_id === currentGeofenceId,
      );

      if (currentGeofence) {
        const polygonCoordinates =
          // @ts-ignore
          currentGeofence.geo_polygon.coordinates[0].map(
            (coordinate: number[]) => ({
              lat: coordinate[1],
              lng: coordinate[0],
            }),
          );

        // Verifique se as coordenadas são válidas antes de tentar usá-las
        const validCoordinates = polygonCoordinates.filter(
          // @ts-ignore
          ({ lat, lng }) => !isNaN(lat) && !isNaN(lng),
        );

        if (validCoordinates.length > 0) {
          const bounds = new window.google.maps.LatLngBounds();
          validCoordinates.forEach(
            (coordinate: google.maps.LatLng | google.maps.LatLngLiteral) => {
              bounds.extend(coordinate);
            },
          );
          map.fitBounds(bounds);
          map.setZoom(17);
        } else {
          console.error(
            'Coordenadas de geofence inválidas:',
            polygonCoordinates,
          );
        }
      } else {
        const center = {
          lat: VIEW_STATE.latitude,
          lng: VIEW_STATE.longitude,
        };

        map.panTo(center);
        map.setZoom(15);
      }
    } catch (error) {
      console.error('Erro ao centralizar geofence:', error);
    }
  }, [map, geofences, currentGeofenceId]);

  React.useEffect(() => {
    handleRecenterGeofence();
  }, [handleRecenterGeofence, currentGeofenceId]);

  const toggleLayerVisibility = (layerId: string) => {
    if (map) {
      // Implement the logic to toggle visibility using Google Maps APIs
    }
  };

  return (
    <Stack sx={{ flex: '1 1 auto', position: 'relative' }}>
      <CustomMaps
        mapContainerStyle={{ height: '100%', width: '100%' }}
        // @ts-ignore
        setMapCallback={setMap}
        shouldCenterMap={false}
        zoom={VIEW_STATE.zoom}
        center={{ lat: VIEW_STATE.latitude, lng: VIEW_STATE.longitude }}
      >
        {geofences.map((geofence) => {
          // @ts-ignore
          const polygonCoordinates = geofence.geo_polygon.coordinates[0].map(
            (coordinate: number[]) => ({
              lat: coordinate[1],
              lng: coordinate[0],
            }),
          );

          const center = calculatePolygonCenter(polygonCoordinates);

          return (
            <>
              <Polygon
                key={geofence.geo_id}
                paths={polygonCoordinates}
                options={{
                  fillColor: geofence.geo_color,
                  strokeColor: geofence.geo_color,
                  strokeOpacity: 0.1,
                  strokeWeight: 0.5,
                  fillOpacity: 0.2,
                }}
              />
              <MarkerF
                position={center}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 0,
                }}
                label={{
                  text: geofence.geo_name,
                  color: geofence.geo_color,
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              />
            </>
          );
        })}

        {vehicles.map((vehicle) => {
          const isSelected = currentVehicleId === vehicle.vehicle.vhc_id;
          // return (
          //   <MarkerF
          //     key={`vehicle-${vehicle.vehicle.vhc_id}-${currentVehicleId}-${vehicle.fb.latitude}-${vehicle.fb.longitude}-${vehicle.fb.azimuth}-${vehicle.fb.ignition}-${vehicle.fb.speed}`}
          //     position={{
          //       lat: Number(vehicle.fb.latitude),
          //       lng: Number(vehicle.fb.longitude),
          //     }}
          //     icon={{
          //       path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          //       scale: isSelected ? 12 : 3,
          //       rotation: vehicle.fb.azimuth ? Number(vehicle.fb.azimuth) : 0,
          //       strokeColor: vehicle.fb.ignition ? '#00FF00' : '#FF0000',
          //       labelOrigin: new window.google.maps.Point(
          //         DEFAULT_MAPS.icon.labelOrigin.x,
          //         DEFAULT_MAPS.icon.labelOrigin.y + 6,
          //       ),
          //       anchor: new window.google.maps.Point(0, 0),
          //     }}
          //     label={{
          //       text: vehicle.vehicle.vhc_name,
          //       color: vehicle.fb.ignition ? '#00FF00' : '#FF0000',
          //       fontSize:
          //         currentVehicleId === vehicle.vehicle.vhc_id ? '14px' : '10px',
          //       fontWeight:
          //         currentVehicleId === vehicle.vehicle.vhc_id
          //           ? 'bold'
          //           : 'normal',
          //     }}
          //   />
          // );
          const uniqueKey = `vehicle-${vehicle.vehicle.vhc_id}-${currentVehicleId}-${vehicle.fb.latitude}-${vehicle.fb.longitude}-${vehicle.fb.azimuth}-${vehicle.fb.ignition}-${vehicle.fb.speed}`;
          return (
            <MapsMarker
              key={uniqueKey}
              uniqueKey={uniqueKey}
              vhc_id={vehicle.vehicle.vhc_id}
              value={vehicle.fb}
              labelColor={vehicle.fb.ignition ? '#00FF00' : '#FF0000'}
              map={map}
              icon={{
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: isSelected ? 6 : 3,
                rotation: vehicle.fb.azimuth ? Number(vehicle.fb.azimuth) : 0,
                strokeColor: vehicle.fb.ignition ? '#00FF00' : '#FF0000',
                labelOrigin: new window.google.maps.Point(
                  DEFAULT_MAPS.icon.labelOrigin.x,
                  DEFAULT_MAPS.icon.labelOrigin.y + 6,
                ),
                anchor: new window.google.maps.Point(0, 0),
              }}
              label={{
                text: vehicle.vehicle.vhc_name,
                color: vehicle.fb.ignition ? '#00FF00' : '#FF0000',
                fontSize: isSelected ? '14px' : '10px',
                fontWeight: isSelected ? 'bold' : 'normal',
              }}
            />
          );
        })}
      </CustomMaps>
    </Stack>
  );
}
