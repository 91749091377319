import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Skeleton,
} from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { Page } from 'src/components/layouts/Page';
import GeofenceMap from '../devices/GeofenceMap';

interface IGeofenceListProps {}

export const GeofenceList: React.FC<IGeofenceListProps> = () => {
  const touchwayApi = useTouchwayApi();

  const geofencesQuery = useQuery({
    queryKey: queryKeys.geofences.geofenceControllerFindAll(),
    queryFn: () => touchwayApi.api.geofences.geofenceControllerFindAll(),
  });

  const InternalPage: React.FC = ({ children }) => (
    <Page
      title="Perímetros"
      query={geofencesQuery}
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: 'perimetros', label: 'Perímetros' },
      ]}
    >
      {children}
    </Page>
  );

  if (geofencesQuery.isLoading) {
    return (
      <InternalPage>
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
        </Stack>
      </InternalPage>
    );
  }

  if (!geofencesQuery.data) {
    return (
      <InternalPage>
        <EmptyPlaceholder
          title="Nenhum dado encontrado"
          subtitle="Não há dados disponíveis para o período selecionado."
        />
      </InternalPage>
    );
  }

  return (
    <InternalPage>
      <Grid container spacing={2}>
        {geofencesQuery.data.map((geofence) => (
          <Grid item xs={12} sm={6} md={6} key={geofence.geo_id}>
            <Card>
              <CardMedia>
                {geofence && <GeofenceMap geofence={geofence} />}
              </CardMedia>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>{geofence.geo_name}</strong> (Cor:{' '}
                  {geofence.geo_color})
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </InternalPage>
  );
};
