import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import {
  IEquipmentControllerMaintenanceListParamsEqmArchivedEnum,
  IReportListDto,
  ITypeEquipmentMaintenance,
  IVehicleListGeofencesDto,
  IVehicleListPositionsRequestDto,
  RequestParams,
} from 'src/services/touchway-base-api';

export const queryKeys = createQueryKeyStore({
  audits: {
    twillio: () => ['audits', 'twillio'],
  },
  autocompleteControllerAirportList: {
    get: ({ limit, params }: { limit: number; params?: RequestParams }) => [
      'autocomplete',
      'airport',
      'list',
      limit,
      params,
    ],
  },
  autocompleteControllerSimcards: {
    get: () => ['autocomplete', 'simcards'],
  },
  autocompleteControllerLocators: {
    get: () => ['autocomplete', 'locators'],
  },
  autocompleteControllerTypeVehicles: {
    get: () => ['autocomplete', 'type-vehicles'],
  },
  autocompleteControllerTypeMaintenance: {
    get: () => ['autocomplete', 'type-maintenances'],
  },
  autocompleteControllerStatusMaintenance: {
    get: () => ['autocomplete', 'status-maintenances'],
  },
  autocompleteControllerProfiles: {
    get: () => ['autocomplete', 'profiles'],
  },
  autocompleteControllerAirports: {
    get: () => ['autocomplete', 'airports'],
  },
  autocompleteControllerVehicles: {
    get: () => ['autocomplete', 'vehicles'],
  },
  autocompleteControllerTypeImages: {
    get: () => ['autocomplete', 'type-images'],
  },
  autocompleteControllerTypeEquipmentMaintenance: {
    get: () => ['autocomplete', 'type-equipment-maintenance'],
  },
  configControllerList: {
    get: () => ['config', 'list'],
  },
  locatorControllerList: {
    get: ({
      lct_imei,
      lct_cell_number,
      with_relations,
    }: {
      lct_imei: string | undefined;
      lct_cell_number: string | undefined;
      with_relations?: boolean;
    }) => ['locator', 'list', lct_imei, lct_cell_number, with_relations],
    history: ({
      end,
      lct_id,
      limit,
      start,
    }: {
      end: string;
      start: string;
      limit: number;
      lct_id: string;
    }) => ['locator', 'history', end, lct_id, limit, start],
  },
  maintenanceTypesList: {
    get: () => ['maintenance', 'types', 'list'],
  },
  images: {
    get: (vhc_id: string) => ['images', 'list', vhc_id],
  },
  maintenanceControllerList: {
    get: ({
      vhc_id,
      tpm_id,
      mts_id,
      vmp_execution_date,
      with_relations,
    }: {
      vhc_id?: string;
      tpm_id?: string;
      mts_id?: string;
      vmp_execution_date?: string;
      with_relations?: boolean;
    }) => [
      'maintenance',
      'list',
      vhc_id,
      tpm_id,
      mts_id,
      vmp_execution_date,
      with_relations,
    ],
  },
  simControllerList: {
    get: (query?: {
      with_relations?: boolean;
      sim_id?: string;
      sim_phone_number?: string;
      sim_iccid?: string;
      sim_pin?: string;
      sim_pin2?: string;
      sim_puk?: string;
      sim_puk2?: string;
    }) => ['simcards', 'list', query],
  },
  vehicles: {
    vehicleControllerListLinkedGeofences: ({ vhc_id }: { vhc_id: string }) => [
      'vehicles',
      'link-geofences',
      vhc_id,
    ],
    vehicleControllerListVehiclePositions: ({
      vhc_id,
      end,
      start,
      limit,
    }: IVehicleListPositionsRequestDto) => [
      'vehicles',
      'positions',
      vhc_id,
      end,
      start,
      limit,
    ],
    vehicleControllerListVehicleGeofences: (
      params: IVehicleListGeofencesDto,
    ) => ['vehicles', 'geofences', params.vhc_id, params.start, params.end],
  },
  geofences: {
    geofenceControllerFindAll: () => ['geofences', 'list'],
  },
  vehicleControllerList: {
    get: (query?: {
      vhc_id?: string | null;
      tpv_id?: string | null;
      lct_id?: string | null;
      vhc_name?: string | null;
      vhc_fuel_avg_cons_lph?: number | null;
      vhc_hour_meter?: number | null;
      with_relations?: boolean | null;
      with_maintenances?: boolean | null;
    }) => ['vehicles', 'list', query],
  },
  // reportControllerReport: {
  //   get: ({ end, start, iata, vhc_ids }: reportController) => [
  //     'report',
  //     end,
  //     start,
  //     iata,
  //     vhc_ids.join(','),
  //   ],
  // },
  equipment: {
    equipmentControllerMaintenanceList: (query?: {
      /**
       * The equipment maintenance UUID
       * @format uuid
       */
      eqm_id?: string | null;
      /**
       * The equipment UUID
       * @format uuid
       */
      eqp_id?: string | null;
      /**
       * The equipment type maintenance UUID
       * @format uuid
       */
      tem_id?: string | null;
      /**
       * The maintenance type UUID
       * @format uuid
       */
      mts_id?: string | null;
      /**
       * The equipment maintenance archived flag to filter the list and show only active maintenance cards
       * @default "false"
       */
      eqm_archived?: IEquipmentControllerMaintenanceListParamsEqmArchivedEnum;
    }) => ['equipment', 'maintenance', 'list', query],
    get: (query?: {
      /**
       * Equipment id
       * @format uuid
       */
      eqp_id?: string | null;
      /** eqp_name to filter equipment */
      eqp_name?: string | null;
      /** eqp_tag to filter equipment */
      eqp_tag?: string | null;
      /** eqp_serial_number to filter equipment */
      eqp_serial_number?: string | null;
      /** eqp_external_id to filter equipment */
      eqp_external_id?: string | null;
      /**
       * A flag to return relationships of the equipmet like maintenances and type_maintenance
       * @default false
       * @example true
       */
      with_relations?: boolean;
    }) => ['equipments', 'list', query],
  },
  typeEquipmentMaintenance: {
    get: (params: { tem_id: string }) => [
      'type-equipment-maintenance',
      'get',
      params,
    ],
    list: (params: { tem_id?: string; tem_name?: string }) => [
      'type-equipment-maintenance',
      'list',
      params,
    ],
  },
});

interface reportController extends Omit<IReportListDto, 'vhc_ids'> {
  vhc_ids: string[];
}
