import React, { useEffect, useState } from 'react';
import { Marker, Polygon } from '@react-google-maps/api';
import { CustomMaps } from 'src/components/CustomMaps';
import { IGeofence } from 'src/services/touchway-base-api';

interface GeofenceMapProps {
  geofence: IGeofence;
  point?: LatLng;
}

export interface LatLng {
  lat: number;
  lng: number;
}

const GeofenceMap: React.FC<GeofenceMapProps> = ({ geofence, point }) => {
  const [map, setMap] = useState(null);

  // @ts-ignore: Mapeia as coordenadas para um array de objetos LatLng
  const paths: LatLng[] = geofence.geo_polygon.coordinates[0].map(
    (coordinate: number[]) => ({
      lat: coordinate[1],
      lng: coordinate[0],
    }),
  );

  // Calcula o centro do polígono (ou do ponto, se houver)
  const center = point
    ? { lat: point.lat, lng: point.lng }
    : paths.reduce<LatLng>(
        (acc, path) => ({
          lat: acc.lat + path.lat / paths.length,
          lng: acc.lng + path.lng / paths.length,
        }),
        { lat: 0, lng: 0 },
      );

  useEffect(() => {
    if (map && paths.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      // Inclui todas as coordenadas do polígono nos limites do mapa
      paths.forEach((path) => {
        bounds.extend(new google.maps.LatLng(path.lat, path.lng));
      });

      // Se houver um ponto, adiciona-o aos limites
      if (point) {
        bounds.extend(new google.maps.LatLng(point.lat, point.lng));
      }

      // @ts-ignore: Ajusta o zoom e o centro para incluir o polígono e o ponto
      map.fitBounds(bounds);
    }
  }, [map, paths, point]);

  return (
    <CustomMaps
      center={center}
      defaultHeight={300}
      setMapCallback={setMap}
      shouldCenterMap={false}
    >
      <Polygon
        paths={paths}
        options={{
          fillColor: geofence.geo_color,
          fillOpacity: 0.4,
          strokeColor: geofence.geo_color,
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />
      {point && (
        <Marker position={new google.maps.LatLng(point.lat, point.lng)} />
      )}
    </CustomMaps>
  );
};

export default GeofenceMap;
