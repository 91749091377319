import { Box, Container, Typography, Button } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

interface EmptyPlaceholderInterface {
  title: string;
  subtitle: string;
  path?: string;
  showImage?: boolean;
  titleSize?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2';
  buttonTitle?: string;
  onClick?: () => void;
}

const EmptyPlaceholder: React.FC<EmptyPlaceholderInterface> = ({
  title,
  subtitle,
  path,
  showImage = true,
  buttonTitle,
  titleSize = 'h3',
  onClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container>
      <Box
        display="flex"
        // minHeight="40em"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}
      >
        {showImage && (
          <Box mb={2}>
            <img
              height="100px"
              width="auto"
              src={`/static/error/error500_${theme.palette.mode}.svg`}
              alt=""
            />
          </Box>
        )}

        <Typography variant={titleSize} gutterBottom>
          {title}
        </Typography>

        <Box>
          <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
        </Box>

        {/* <Box
            component={motion.img}
            variants={varBounceIn}
            alt="404"
            src="/static/illustrations/illustration_register.svg"
            sx={{ width: '100%', maxHeight: 240, my: { xs: 5, sm: 10 } }}
          /> */}

        {buttonTitle && (
          <Box mt={5}>
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                if (onClick) onClick();
                if (path) navigate(path);
              }}
            >
              {buttonTitle}
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default EmptyPlaceholder;
