import React from 'react';
import { MarkerF } from '@react-google-maps/api';
import { Box, Link } from '@material-ui/core';
import ReactDOMServer from 'react-dom/server';
import GseStaticCard from 'src/pages/gses/components/GseStaticCard';
import { DEFAULT_MAPS } from 'src/config/maps-default';
import { IVehicleFirebaseRealtimeType } from '../gses/types/IGSEFirebaseRealtime.type';

interface MapsMarkerProps {
  vhc_id: string;
  value: IVehicleFirebaseRealtimeType;
  labelColor: string;
  map: any;
  clusterer?: any;
  icon?: string | google.maps.Icon | google.maps.Symbol | undefined;
  label?: string | google.maps.MarkerLabel | undefined;
  uniqueKey?: string;
}

const MapsMarker: React.FC<MapsMarkerProps> = ({
  value,
  vhc_id,
  clusterer,
  labelColor,
  icon,
  label,
  map,
  uniqueKey,
}) => {
  const { ignition, latitude, longitude, name, speed, azimuth } = value;

  // Convert speed and azimuth to numbers if they are strings
  const vehicleSpeed = typeof speed === 'string' ? parseFloat(speed) : speed;
  const vehicleAzimuth =
    typeof azimuth === 'string' ? parseFloat(azimuth) : azimuth;

  return (
    <>
      <MarkerF
        key={uniqueKey}
        icon={
          icon || {
            path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
            size: new window.google.maps.Size(
              DEFAULT_MAPS.icon.size.width,
              DEFAULT_MAPS.icon.size.height,
            ),
            scale: DEFAULT_MAPS.icon.scale,
            scaledSize: new window.google.maps.Size(
              DEFAULT_MAPS.icon.size.width,
              DEFAULT_MAPS.icon.size.height,
            ),
            labelOrigin: new window.google.maps.Point(
              DEFAULT_MAPS.icon.labelOrigin.x,
              DEFAULT_MAPS.icon.labelOrigin.y + 5,
            ),
            strokeColor: DEFAULT_MAPS.icon.color.ignition.on,
            rotation: vehicleAzimuth, // Apply rotation based on azimuth
          }
        }
        position={{
          lat: Number(latitude),
          lng: Number(longitude),
        }}
        label={
          label || {
            text: name
              ? `${name} (${vehicleSpeed ? `${vehicleSpeed} km/h` : 'Parado'})`
              : '',
            color: labelColor,
            fontWeight: 'bold',
          }
        }
        title="Clique para ver mais informações"
        clusterer={clusterer}
        onClick={() => {
          if (!name) {
            return;
          }

          const content = ReactDOMServer.renderToStaticMarkup(
            <Box width="350px">
              <GseStaticCard
                showIgnitionStatus
                showCard={false}
                vehicle={value}
              />
              <Box mx={2}>
                <Link
                  component="a"
                  variant="button"
                  href={`/veiculos/${vhc_id}`}
                  underline="always"
                >
                  Ver mais
                </Link>
              </Box>
            </Box>,
          );

          const lat = Number(latitude);
          const lng = Number(longitude);

          const pos = { lat, lng };
          const infoWindow = new google.maps.InfoWindow({
            position: pos,
            pixelOffset: new google.maps.Size(0, -70),
            content,
          });

          infoWindow.open(map);
        }}
      />
    </>
  );
};

export default MapsMarker;
