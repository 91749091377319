'use client';

import * as React from 'react';

import {
  Stack,
  Avatar,
  Typography,
  Collapse,
  Divider,
  Box,
  LinearProgress,
} from '@material-ui/core';
import { Timeline } from '@material-ui/icons';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';
import { DevicePhotoAvatar } from 'src/pages/devices/components/DeviceImage/DevicePhotoAvatar';
import { ImageTpinameEnum } from 'src/utils/getImageName';
import PulsingBadge from 'src/components/PulsingBadge';
import type { Vehicle } from './types';

export interface FleetVehicleProps {
  onDeselect?: () => void;
  onSelect?: (vehicleId: string) => void;
  selected?: boolean;
  vehicle: Vehicle;
}

export function FleetVehicle({
  onDeselect,
  onSelect,
  selected,
  vehicle,
}: FleetVehicleProps): JSX.Element {
  const handleToggle = React.useCallback(() => {
    if (!selected) {
      onSelect?.(vehicle.vehicle.vhc_id);
    } else {
      onDeselect?.();
    }
  }, [onDeselect, onSelect, selected, vehicle]);

  return (
    <Stack
      component="li"
      style={{
        cursor: 'pointer',
      }}
      bgcolor={selected ? 'action.selected' : 'transparent'}
    >
      <Stack
        direction="row"
        onClick={handleToggle}
        onKeyUp={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleToggle();
          }
        }}
        role="button"
        spacing={2}
        alignItems="center"
        // cursor="pointer"
        display="flex"
        padding={2}
        textAlign="left"
        width="100%"
        tabIndex={0}
      >
        <Stack flexGrow={1}>
          <Stack
            direction="row"
            spacing={2}
            flex={1}
            width="100%"
            sx={{ justifyContent: 'space-between' }}
            flexGrow={1}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <DevicePhotoAvatar
                vhc_id={vehicle.vehicle.vhc_id}
                image={ImageTpinameEnum.VEHICLE}
              />
              <Stack direction="column">
                <Typography variant="caption">
                  {vehicle.vehicle.vhc_name}
                </Typography>
                <Typography color="text.secondary" variant="caption">
                  {vehicle.fb.iata}
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={0.5} alignItems="right">
              <PulsingBadge
                variant={vehicle.fb.ignition ? 'success' : 'danger'}
                withBorder={false}
                badgeLabel={vehicle.fb.ignition ? 'Ligado' : 'Desligado'}
              />
              <Typography
                color="text.secondary"
                variant="caption"
                align="right"
              >
                {vehicle.fb.speed} km/h
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

/* <Collapse in={selected}>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="caption">
              Temperature (good)
            </Typography>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <LinearProgress
                sx={{ flex: '1 1 auto' }}
                // value={vehicle.temperature}
                variant="determinate"
              />
              <Typography color="text.secondary" variant="body2">
              </Typography>
            </Stack>
          </Stack>
          <Timeline
            sx={{ px: 3, '& .MuiTimelineItem-root:before': { flex: 0, p: 0 } }}
          >
            {true ? (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div>
                    <Typography variant="body2">Arrived</Typography>
                    <Typography color="text.secondary" variant="caption">
                      22/09/2021 10:00
                    </Typography>
                  </div>
                </TimelineContent>
              </TimelineItem>
            ) : null}
            {true ? (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div>
                    <Typography variant="body2">Out for delivery</Typography>
                    <Typography color="text.secondary" variant="caption">
                      22/09/2021 10:00
                    </Typography>
                  </div>
                </TimelineContent>
              </TimelineItem>
            ) : null}
            {true ? (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                </TimelineSeparator>
                <TimelineContent>
                  <div>
                    <Typography variant="body2">
                      Tracking number created
                    </Typography>
                    <Typography color="text.secondary" variant="caption">
                      22/09/2021 10:00
                    </Typography>
                  </div>
                </TimelineContent>
              </TimelineItem>
            ) : null}
          </Timeline>
        </Box>
      </Collapse> */
