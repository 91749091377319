import * as React from 'react';

import { Stack, Divider } from '@material-ui/core';
import { FleetVehicle } from './fleet-vehicle';
import type { Vehicle } from './types';

export interface FleetVehiclesProps {
  currentVehicleId?: string;
  onVehicleDeselect: () => void;
  onVehicleSelect: (vehicleId: string) => void;
  onGeofenceDeSelect?: () => void;
  vehicles?: Vehicle[];
}

export function FleetVehicles({
  onVehicleDeselect,
  onVehicleSelect,
  onGeofenceDeSelect,
  currentVehicleId,
  vehicles = [],
}: FleetVehiclesProps): JSX.Element {
  return (
    <Stack
      component="ul"
      divider={<Divider />}
      sx={{
        borderBottom: '1px solid var(--mui-palette-divider)',
        listStyle: 'none',
        m: 0,
        p: 0,
      }}
    >
      {vehicles.map((vehicle) => {
        const selected = currentVehicleId
          ? currentVehicleId === vehicle.vehicle.vhc_id
          : false;

        return (
          <FleetVehicle
            key={vehicle.vehicle.vhc_id}
            onDeselect={() => {
              if (onGeofenceDeSelect) {
                onGeofenceDeSelect();
              }
              onVehicleDeselect();
            }}
            onSelect={onVehicleSelect}
            selected={selected}
            vehicle={vehicle}
          />
        );
      })}
    </Stack>
  );
}
