import React, { useState } from 'react';
import { Stack } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import ptBR from 'date-fns/locale/pt-BR';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  disabledInput: {
    cursor: 'pointer',
  },
}));

interface OverviewDateRange2Interface {
  startDate?: Date;
  setStartDate?: React.Dispatch<React.SetStateAction<Date>>;
  endDate?: Date;
  setEndDate?: React.Dispatch<React.SetStateAction<Date>>;
  align?: 'row' | 'column';
}

export const OverviewDateRange2: React.FC<OverviewDateRange2Interface> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  align = 'column',
}) => {
  const classes = useStyles();
  const [endDateError, setEndDateError] = useState<string | null>(null);

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setStartDate?.(date);
      if (endDate && date > endDate) {
        setEndDate?.(date); // Atualiza o endDate para ser igual ao startDate, se necessário
      }
      setEndDateError(null); // Limpa o erro ao ajustar a startDate
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      if (startDate && date < startDate) {
        setEndDateError(
          'A data de término não pode ser anterior à data de início.',
        );
      } else {
        setEndDate?.(date);
        setEndDateError(null);
      }
    }
  };

  // Função para evitar que o usuário digite no campo
  const preventManualInput = (event: React.KeyboardEvent) => {
    event.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <Stack direction={align} spacing={align === 'row' ? 1 : 0}>
        <DateTimePicker
          label="Data e Hora de Início"
          value={startDate}
          onChange={handleStartDateChange}
          renderInput={(props) => (
            <TextField
              {...props}
              className={`${classes.input} ${classes.disabledInput}`}
              placeholder={undefined}
              helperText={undefined}
              onKeyDown={preventManualInput} // Previne a entrada manual
              InputProps={{
                ...props.InputProps,
                readOnly: true,
                onClick: props.InputProps?.onClick, // Mantém a abertura do calendário ao clicar
              }}
            />
          )}
        />
        <DateTimePicker
          label="Data e Hora de Término"
          value={endDate}
          onChange={handleEndDateChange}
          minDate={startDate}
          minDateTime={startDate}
          renderInput={(props) => (
            <TextField
              {...props}
              className={`${classes.input} ${classes.disabledInput}`}
              error={!!endDateError}
              helperText={endDateError || undefined}
              onKeyDown={preventManualInput} // Previne a entrada manual
              InputProps={{
                ...props.InputProps,
                readOnly: true,
                onClick: props.InputProps?.onClick, // Mantém a abertura do calendário ao clicar
              }}
              placeholder={undefined}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default OverviewDateRange2;
