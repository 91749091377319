import { useMemo } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  AppSettingsAlt,
  Build,
  Dashboard,
  GpsOff,
  PendingActions,
  PinDropRounded,
  ShareLocation,
  Summarize,
  Tv,
  ViewList,
} from '@material-ui/icons';
import useLayout from 'src/hooks/useLayout';
import useSettings from 'src/hooks/useSettings';
import Calendar from 'src/icons/Calendar';
import useAuth from '../../hooks/useAuth';

import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = () => {
  const { closeMenu, isSidebarMobileOpen } = useLayout();
  const location = useLocation();
  const { user } = useAuth();
  const { settings } = useSettings();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const sections = useMemo(() => {
    let menu = [];
    const general = {
      title: 'Geral',
      items: [
        {
          title: 'Dashboard',
          path: '/dashboard',
          icon: <Dashboard fontSize="small" />,
        },
        {
          title: 'Paínel',
          path: '/realtime',
          icon: <Tv fontSize="small" />,
        },
        {
          title: 'Mapa',
          path: '/mapa',
          icon: <PinDropRounded fontSize="small" />,
        },
        {
          title: 'Mapa Trolleys',
          path: '/mapa-trolleys',
          icon: <PinDropRounded fontSize="small" />,
          hide: !settings.showTrolleys,
        },
      ],
    };
    const gestaoEnergized = {
      title: 'Gestão rastreados',
      items: [
        {
          title: 'Veículos rastreados',
          path: '/veiculos',
          icon: <ShareLocation fontSize="small" />,
        },
        {
          title: 'Trolleys',
          path: '/trolleys',
          icon: <ViewList fontSize="small" />,
          hide: !settings.showTrolleys,
        },
        {
          title: 'Gestão de manutenção',
          path: '/kanban',
          icon: <Build fontSize="small" />,
          hide: false,
        },
        {
          // perimetro
          title: 'Perímetros',
          path: '/perimetros',
          icon: <PinDropRounded fontSize="small" />,
          hide: false,
        },
      ],
    };
    const gestaoNotEnergized = {
      title: 'Gestão não rastreados',
      items: [
        {
          title: 'Eqps não rastreados',
          path: '/equipamentos',
          icon: <GpsOff fontSize="small" />,
        },
        {
          title: 'Calendário',
          path: '/equipamentos/calendario',
          icon: <Calendar fontSize="small" />,
          hide: false,
        },
        {
          title: 'Configuração',
          path: '/frequencia-manutencao',
          icon: <PendingActions fontSize="small" />,
          hide: false,
        },
      ],
    };
    // const relatorios = {
    //   title: 'Relatórios',
    //   items: [
    //     {
    //       title: 'Completo',
    //       path: '/relatorios/equipamentos',
    //       icon: <Summarize fontSize="small" />,
    //     },
    //     {
    //       title: 'Instalação',
    //       path: '/relatorios/instalacao',
    //       icon: <Summarize fontSize="small" />,
    //     },
    //   ],
    // };
    const tecnicos = {
      title: 'Outros',
      items: [
        {
          title: 'Notificações',
          path: '/notificacoes',
          // notification icon
          icon: <NotificationsNoneIcon fontSize="small" />,
        },
        {
          title: 'Instalador',
          path: '/instalador',
          icon: <AppSettingsAlt fontSize="small" />,
        },
      ],
    };

    menu = [general, gestaoEnergized, gestaoNotEnergized];

    menu.push(tecnicos);
    // if (isAdmin) {
    // }

    return menu;
  }, [settings.showTrolleys]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <Avatar
              // src="https://res.cloudinary.com/ddfytxkn4/image/upload/v1688753597/Design_sem_nome_1_1_anzlt4.png"
              sx={{
                bgcolor: 'white',
                height: 32,
                width: 32,
              }}
            >
              {user?.usu_name[0].toUpperCase()}
            </Avatar>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user?.usu_name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user?.profile?.prf_name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={closeMenu}
      open={isSidebarMobileOpen}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
