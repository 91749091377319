import { forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FleetView } from 'src/components/dashboard/logistics/fleet-view';
import { firebaseRealtimeEnum } from 'src/config';
import useRealtime, {
  IGseFirebaseCollection,
} from 'src/hooks/firebase/useRealtime';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { IVehicle } from 'src/services/touchway-base-api';
import { ICompleteMapVehicle } from 'src/components/dashboard/logistics/types';
import { IVehicleFirebaseRealtimeType } from '../gses/types/IGSEFirebaseRealtime.type';

interface IVehicleMapsProps {}

export const VehicleMaps: React.FC<IVehicleMapsProps> = () => {
  const [formattedVehicles, setFormattedVehicles] = useState<
    ICompleteMapVehicle[]
  >([]);

  const { api } = useTouchwayApi();
  const vehiclesQuery = useQuery(
    queryKeys.vehicleControllerList.get({
      with_relations: false,
    }),
    () =>
      api.vehicles.vehicleControllerList({
        // @ts-ignore
        with_relations: true,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const vehicles = useRealtime<IGseFirebaseCollection>({
    collection: firebaseRealtimeEnum.vehicles,
  });

  const geofencesQuery = useQuery({
    queryKey: queryKeys.geofences.geofenceControllerFindAll(),
    queryFn: () => api.geofences.geofenceControllerFindAll(),
  });

  const geofences = geofencesQuery.data ?? [];

  const vehiclesData = vehiclesQuery?.data?.vehicles || [];
  useEffect(() => {
    if (!vehicles) {
      return;
    }

    const tmpArr: ICompleteMapVehicle[] = [];
    Object.entries(vehicles).forEach((entry) => {
      const [key, value] = entry;

      if (value.name.startsWith('IMEI')) {
        return;
      }

      const vehicle = vehiclesData.find((v) => v.vhc_id === key);

      if (!vehicle) {
        return;
      }

      tmpArr.push({
        fb: value,
        vehicle: vehicle as IVehicle,
      });
    });

    setFormattedVehicles(tmpArr);
  }, [vehicles, vehiclesData]);

  return <FleetView vehicles={formattedVehicles} geofences={geofences} />;
};
