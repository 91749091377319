import { FC, useCallback, useMemo, useState } from 'react';
import { Button, Grid, Stack } from '@material-ui/core';
import { ConnectedTv, SettingsRemote } from '@material-ui/icons';
import { Page } from 'src/components/layouts/Page';
import { useQuery } from '@tanstack/react-query';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import { useNavigate } from 'react-router';
import { useTouchwayApi } from 'src/services/touchway-api';
import { BrowserView } from 'react-device-detect';
import { queryKeys } from 'src/config/query';
import useSettings from 'src/hooks/useSettings';
import { IVehicle, IVehicleSimple } from 'src/services/touchway-base-api';
import { GseSocketCard } from './components/GseSocketCard';
import { SelectAutocompleteAirport } from '../airports/components/SelectAutocompleteAirport';
import useAirports from '../airports/hooks/useAirports';

interface IIUpdateList {
  [key: string]: {
    ignition: boolean;
    base: string;
  };
}

export const GseRealtime: FC = () => {
  const { selectedAirports } = useAirports();
  const [updateList, setUpdateList] = useState<IIUpdateList>({});
  const { settings } = useSettings();
  const touchwayApi = useTouchwayApi();
  const navigate = useNavigate();

  const vehiclesQuery = useQuery(
    queryKeys.vehicleControllerList.get(),
    () => touchwayApi.api.vehicles.vehicleControllerList(),
    {
      // 1 hour
      refetchInterval: 1000 * 60 * 60,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const queryResponse = useMemo(() => {
    const vehiclesRaw = vehiclesQuery.data?.vehicles || [];
    // Remove all vehicles that starts with name "IMEI"
    // return vehiclesRaw;
    return vehiclesRaw.filter(({ vhc_name }) => !vhc_name.startsWith('IMEI'));
  }, [vehiclesQuery.data]);

  const onChangeIgnition = useCallback(
    ({ ignition, vhc_id }: { ignition: boolean; vhc_id: string }) => {
      setUpdateList((prevState) => ({
        ...prevState,
        [vhc_id]: {
          ...prevState[vhc_id],
          ignition,
        },
      }));
    },
    [setUpdateList],
  );

  const onChangeBase = useCallback(
    ({ base, vhc_id }: { base: string; vhc_id: string }) => {
      setUpdateList((prevState) => ({
        ...prevState,
        [vhc_id]: {
          ...prevState[vhc_id],
          base,
        },
      }));
    },
    [setUpdateList],
  );

  function openTvMode() {
    navigate('/live/tv');
  }

  function openTvSettings() {
    window.open('/live/controle', '_blank');
  }

  return (
    <Page
      title="Equipamentos em tempo real"
      breadcrumbs={[
        { to: '/', label: 'Painel' },
        { to: 'Dispositivos', label: 'Equipamentos' },
      ]}
      query={vehiclesQuery}
      disabledFetching
      buttons={[
        <BrowserView>
          <Stack direction="row" spacing={1}>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              style={{ borderRadius: 4 }}
              onClick={openTvMode}
              startIcon={<ConnectedTv fontSize="small" />}
            >
              Abrir em modo TV
            </Button>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              style={{ borderRadius: 4 }}
              onClick={openTvSettings}
              startIcon={<SettingsRemote fontSize="small" />}
            >
              Configurações do modo TV
            </Button>
            <SelectAutocompleteAirport />
          </Stack>
        </BrowserView>,
      ]}
    >
      <Grid container spacing={2}>
        {queryResponse.map((gse) => (
          <RenderGseSocketCard
            key={gse.vhc_id}
            gse={gse}
            updateList={updateList}
            selectedAirports={selectedAirports}
            onChangeIgnition={onChangeIgnition}
            onChangeBase={onChangeBase}
          />
        ))}

        {queryResponse.length === 0 && (
          <EmptyPlaceholder
            showImage={false}
            title="Ooops"
            subtitle="Nenhuma GSE foi encontrada nas bases selecionadas"
          />
        )}
      </Grid>
    </Page>
  );
};

export const RenderGseSocketCard: FC<{
  gse: IVehicleSimple;
  updateList?: IIUpdateList;
  selectedAirports: any[];
  onChangeIgnition: (data: { ignition: boolean; vhc_id: string }) => void;
  onChangeBase: (data: { base: string; vhc_id: string }) => void;
}> = ({
  gse,
  updateList,
  selectedAirports,
  onChangeIgnition,
  onChangeBase,
}) => {
  const [order, setOrder] = useState<number>(0);
  const { settings } = useSettings();
  const navigate = useNavigate();
  const update = updateList ? updateList[gse.vhc_id] : undefined;

  const show =
    selectedAirports.length === 0
      ? true
      : selectedAirports.map(({ value }) => value).includes(update?.base || '');

  return (
    <Grid
      key={`grid-socket-${gse.vhc_id}`}
      item
      lg={3}
      md={4}
      sm={4}
      xs={12}
      display={show ? 'block' : 'none'}
      order={order}
    >
      <GseSocketCard
        p={0}
        onClick={() => navigate(`/veiculos/${gse.vhc_id}`)}
        onChangeIgnition={(ignitionCallback) =>
          onChangeIgnition({
            ignition: ignitionCallback,
            vhc_id: gse.vhc_id,
          })
        }
        onChangeBase={(baseCallback) =>
          onChangeBase({ base: baseCallback, vhc_id: gse.vhc_id })
        }
        showPhoto={settings.showCardPhotoOnRealtime}
        showTelemetry={settings.enableTelemetry}
        showLastUpdate={settings.enableLastUpdate}
        vehicle={gse}
        onChangeOrder={(newOrder) => setOrder(newOrder)}
      />
    </Grid>
  );
};

export default GseRealtime;
