'use client';

import * as React from 'react';

import { Box } from '@material-ui/core';
import { IGeofence } from 'src/services/touchway-base-api';
import { FleetMap } from './fleet-map';
import { Sidebar } from './sidebar';
import { ICompleteMapVehicle } from './types';

export interface FleetViewProps {
  vehicles: ICompleteMapVehicle[];
  geofences: IGeofence[];
}

export function FleetView({
  vehicles,
  geofences,
}: FleetViewProps): JSX.Element {
  const [openSidebar, setOpenSidebar] = React.useState<boolean>(true);
  const [currentVehicleId, setCurrentVehicleId] = React.useState<
    string | undefined
  >(vehicles[0]?.vehicle.vhc_id);

  const [currentGeofenceId, setCurrentGeofenceId] = React.useState<
    string | undefined
  >(undefined);

  const handleVehicleSelect = React.useCallback((vehicleId: string) => {
    setCurrentVehicleId(vehicleId);
  }, []);

  const handleVehicleDeselect = React.useCallback(() => {
    setCurrentVehicleId(undefined);
  }, []);

  const handleGeofenceSelect = React.useCallback((geofenceId: string) => {
    setCurrentGeofenceId(geofenceId);
  }, []);

  const handleGeofenceDeselect = React.useCallback(() => {
    setCurrentGeofenceId(undefined);
  }, []);

  const handleSidebarOpen = React.useCallback(() => {
    setOpenSidebar(true);
  }, []);

  const handleSidebarClose = React.useCallback(() => {
    setOpenSidebar(false);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 1',
        minHeight: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Sidebar
        currentVehicleId={currentVehicleId}
        currentGeofenceId={currentGeofenceId}
        onClose={handleSidebarClose}
        onVehicleDeselect={handleVehicleDeselect}
        onVehicleSelect={handleVehicleSelect}
        onGeofenceDeselect={handleGeofenceDeselect}
        onGeofenceSelect={handleGeofenceSelect}
        open={openSidebar}
        vehicles={vehicles}
        geofences={geofences}
      />
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <FleetMap
          currentVehicleId={currentVehicleId}
          onVehicleSelect={handleVehicleSelect}
          onGeofenceSelect={handleGeofenceSelect}
          currentGeofenceId={currentGeofenceId}
          vehicles={vehicles}
          geofences={geofences}
        />
      </Box>
    </Box>
  );
}
