import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import { useJsApiLoader } from '@react-google-maps/api';
import { ConfirmProvider } from 'material-ui-confirm';
import RTL from './components/RTL';
import { gtmConfig } from './config';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import useAuth from './hooks/useAuth';
import SplashScreen from './components/SplashScreen';
import { MAPS_STYLES_ID } from './components/CustomMaps';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isLoading } = useAuth();

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings?.direction || 'ltr',
    responsiveFontSizes: settings?.responsiveFontSizes || true,
    roundedCorners: settings?.roundedCorners || true,
    theme: settings.theme,
  });

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    mapIds: [MAPS_STYLES_ID.LIGHT, MAPS_STYLES_ID.DARK],
    libraries: [
      'drawing',
      'places',
      'visualization',
      'geometry',
      'localContext',
    ],
    googleMapsApiKey: 'AIzaSyBWYY9og1wg7qodN5-rCHnRhIj3K98N6Jg',
  });

  if (!isLoaded) {
    return <SplashScreen />;
  }

  if (loadError) {
    return <div>Erro ao carregar o mapa</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider
        defaultOptions={{
          title: 'Excluir',
          description: 'Deseja realmente excluir?',
          confirmationText: 'Sim',
          cancellationText: 'Cancelar',
          confirmationButtonProps: { variant: 'contained', color: 'secondary' },
          cancellationButtonProps: { variant: 'text', color: 'primary' },
        }}
      >
        <RTL direction={settings.direction || 'ltr'}>
          <CssBaseline />
          <Toaster position="top-center" />
          {!isLoading ? content : <SplashScreen />}
        </RTL>
      </ConfirmProvider>
    </ThemeProvider>
  );
};

export default App;
