/* eslint-disable react/no-array-index-key */
import { MarkerF, Polyline, Polygon } from '@react-google-maps/api';
import React, { useState, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useQuery } from '@tanstack/react-query';
import LoadingScreen from 'src/components/LoadingScreen';
import { firebaseRealtimeEnum } from 'src/config';
import useRealtime from 'src/hooks/firebase/useRealtime';
import {
  IPositionsDto,
  IVehicle,
  IVehicleListPositionsRequestDto,
  IVehicleRouteDto,
} from 'src/services/touchway-base-api';
import { DEFAULT_MAPS } from 'src/config/maps-default';
import { CustomMaps } from 'src/components/CustomMaps';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Card,
  CardContent,
  Grid,
  Button,
} from '@material-ui/core';
import { format } from 'date-fns';
import { ExpandMore } from '@material-ui/icons';
import { FleetVehicle } from 'src/components/dashboard/logistics/fleet-vehicle';
import Eye from 'src/icons/Eye';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import { ptBR } from 'date-fns/locale';
import { makeStyles } from '@material-ui/core/styles';
import { AUTH_TOKEN } from 'src/services/axiosService';
import { IVehicleFirebaseRealtimeType } from '../gses/types/IGSEFirebaseRealtime.type';
import { OverviewDateRange2 } from './components/OverviewDateRange2';

interface GSEDetailsWithMapProps {
  gse: IVehicle;
}

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  disabledInput: {
    cursor: 'pointer',
  },
  scrollContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    scrollbarWidth: 'thin', // Para Firefox
    scrollbarColor: `${theme.palette.action.selected} transparent`, // Para Firefox

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.action.selected,
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
  },
}));

export const GSEDetailsWithMap: React.FC<GSEDetailsWithMapProps> = ({
  gse,
}) => {
  const classes = useStyles();
  const [init, setInit] = useState<boolean>(false);
  const [selectedRoute, setSelectedRoute] = useState<number | null>(null);
  const [selectedPositionIndex, setSelectedPositionIndex] = useState<
    number | null
  >(null);
  const [startDate, setStartDate] = useState<Date>(() => {
    const now = new Date();
    now.setHours(now.getHours() - 3);
    return now;
  });

  const [endDate, setEndDate] = useState<Date>(() => {
    const now = new Date();
    now.setHours(now.getHours() + 3);
    return now;
  });
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const touchwayApi = useTouchwayApi();

  const gseRealtime = useRealtime<IVehicleFirebaseRealtimeType>({
    collection: firebaseRealtimeEnum.vehicles,
    key: gse?.vhc_id,
  });

  const isMobileSizePx = isMobile ? '60px' : '0px';

  const params: IVehicleListPositionsRequestDto = {
    vhc_id: gse?.vhc_id,
    limit: 1000,
    start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
      locale: ptBR,
    }),
    end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { locale: ptBR }),
  };
  const positionsQuery = useQuery({
    enabled: !!gse?.vhc_id,
    queryFn: () =>
      touchwayApi.api.vehicles.vehicleControllerListVehiclePositions(params),
    queryKey: queryKeys.vehicles.vehicleControllerListVehiclePositions(params),
  });

  const geofencesQuery = useQuery({
    queryKey: queryKeys.geofences.geofenceControllerFindAll(),
    queryFn: () => touchwayApi.api.geofences.geofenceControllerFindAll(),
  });

  const path =
    selectedRoute !== null &&
    positionsQuery.data &&
    positionsQuery.data[selectedRoute]
      ? positionsQuery.data[selectedRoute].positions.map((position) => ({
          lat: position.lat,
          lng: position.lng,
          azimuth: position.azimuth,
          speed: position.speed,
          has_bypass_speed_limit: position.has_bypass_speed_limit,
        }))
      : positionsQuery.data?.flatMap(({ positions }) =>
          positions.map((position) => ({
            lat: position.lat,
            lng: position.lng,
            azimuth: position.azimuth,
            speed: position.speed,
            has_bypass_speed_limit: position.has_bypass_speed_limit,
          })),
        ) ?? [];

  useEffect(() => {
    if (selectedRoute !== null && path.length > 0 && map) {
      const bounds = new google.maps.LatLngBounds();
      path.forEach((pos) =>
        bounds.extend(new google.maps.LatLng(pos.lat, pos.lng)),
      );
      map.fitBounds(bounds);
    }
  }, [selectedRoute, path, map]);

  const centerLat = gseRealtime?.latitude;
  const centerLng = gseRealtime?.longitude;
  useEffect(() => {
    if (path.length > 0 && map) {
      const bounds = new google.maps.LatLngBounds();
      path.forEach((pos) =>
        bounds.extend(new google.maps.LatLng(pos.lat, pos.lng)),
      );
      map.fitBounds(bounds);
    }
  }, [path, map, startDate, endDate]);

  useEffect(() => {
    if (map && !init && centerLat && centerLng) {
      setInit(true);
      map.setCenter({
        lat: Number(centerLat),
        lng: Number(centerLng),
      });
    }
  }, [map, init, centerLat, centerLng]);

  const markers = path.map((position, index) => {
    const currentPosition =
      selectedRoute !== null
        ? positionsQuery.data?.[selectedRoute]?.positions[index]
        : position;

    return (
      <MarkerF
        key={`marker-${index}-${position.lat}-${position.lng}-${position.azimuth}-${selectedPositionIndex}`}
        position={new google.maps.LatLng(position.lat, position.lng)}
        icon={{
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 3,
          rotation: currentPosition?.azimuth || 0,
          strokeColor: currentPosition?.has_bypass_speed_limit
            ? DEFAULT_MAPS.icon.color.ignition.off
            : DEFAULT_MAPS.icon.color.ignition.on,
          fillColor: currentPosition?.has_bypass_speed_limit
            ? DEFAULT_MAPS.icon.color.ignition.off
            : DEFAULT_MAPS.icon.color.ignition.on,
          labelOrigin: new window.google.maps.Point(0, -10),
        }}
        label={{
          text: `${currentPosition?.speed ?? ''} km/h`,
          color: currentPosition?.has_bypass_speed_limit
            ? DEFAULT_MAPS.icon.color.ignition.off
            : DEFAULT_MAPS.icon.color.ignition.on,
          fontSize: '10px',
        }}
        zIndex={index === selectedPositionIndex ? 999 : 1}
        animation={
          index === selectedPositionIndex
            ? window.google.maps.Animation.BOUNCE
            : undefined
        }
      />
    );
  });

  const handlePositionClick = (index: number) => {
    setSelectedPositionIndex(null); // Primeiro desativa qualquer bounce atual
    setTimeout(() => {
      setSelectedPositionIndex(index); // Então ativa o bounce no novo marcador
      const selectedPosition = path[index];
      if (map) {
        map.panTo(
          new google.maps.LatLng(selectedPosition.lat, selectedPosition.lng),
        );
      }
    }, 0); // O timeout garante que a mudança de estado seja sequencial
  };

  const downloadEndpoint = useMemo(() => {
    const base = `${process.env.REACT_APP_REPORT_URL}`;
    const { start, end } = params;
    return `${base}/?start_date=${start}&end_date=${end}&vhc_id=${
      gse.vhc_id
    }&token=${localStorage.getItem(AUTH_TOKEN)}`;
  }, [params, gse.vhc_id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {gseRealtime ? (
              <Card style={{ cursor: 'pointer' }}>
                <FleetVehicle
                  onSelect={() => {
                    map?.panTo(
                      new google.maps.LatLng(
                        Number(gseRealtime.latitude),
                        Number(gseRealtime.longitude),
                      ),
                    );
                  }}
                  vehicle={{
                    fb: gseRealtime,
                    vehicle: gse,
                  }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  href={downloadEndpoint}
                  target="_blank"
                  rel="noopener noreferrer"
                  fullWidth
                  size="small"
                  style={{
                    borderRadius: '0 0 16px 16px',
                    fontSize: '0.75rem',
                  }}
                  disabled={
                    positionsQuery.isLoading ||
                    positionsQuery.isError ||
                    !positionsQuery.data ||
                    positionsQuery.data.length === 0
                  }
                >
                  Baixar relatório infrações
                </Button>
              </Card>
            ) : (
              <Skeleton variant="rectangular" height={100} />
            )}
          </Grid>

          <Grid item mt={1} mb={-2}>
            <OverviewDateRange2
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          </Grid>

          {positionsQuery.isLoading && (
            <Grid item>
              <Grid container spacing={1}>
                {[1, 2, 3, 4, 5, 6].map((index) => (
                  <Grid item xs={12} key={index}>
                    <Skeleton variant="rectangular" height={75} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          {positionsQuery.data?.length === 0 && (
            <Grid item>
              <Card>
                <CardContent>
                  <EmptyPlaceholder
                    showImage={false}
                    titleSize="h5"
                    title="Nenhuma rota encontrada"
                    subtitle="Não há rotas para o veículo no período selecionado."
                  />
                </CardContent>
              </Card>
            </Grid>
          )}

          {positionsQuery.data && positionsQuery.data.length > 0 && (
            <Grid item>
              <Grid
                component="ul"
                style={{
                  borderBottom: '1px solid var(--mui-palette-divider)',
                  listStyle: 'none',
                  margin: 0,
                  padding: 0,
                }}
              >
                <Card style={{ cursor: 'pointer' }}>
                  {/* <pre>
                    <code>{JSON.stringify(positionsQuery.data, null, 2)}</code>
                  </pre> */}
                  <CardContent style={{ paddingBottom: 1 }}>
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={1}
                    >
                      <Typography variant="h6">Rotas</Typography>
                      <Typography variant="caption">
                        {positionsQuery.data.length} rotas
                      </Typography>
                    </Stack>
                  </CardContent>
                  <div
                    className={classes.scrollContainer}
                    style={{
                      maxHeight: 'calc(45vh)',
                      overflowY: 'auto',
                    }}
                  >
                    {positionsQuery.data.map((route, routeIndex) => (
                      <Accordion
                        key={routeIndex}
                        expanded={selectedRoute === routeIndex}
                        onChange={() => {
                          if (selectedRoute === routeIndex) {
                            setSelectedRoute(null);
                            setSelectedPositionIndex(null);
                          } else {
                            setSelectedRoute(routeIndex);
                            setSelectedPositionIndex(null);
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls={`panel-content-${routeIndex}`}
                          id={`panel-header-${routeIndex}`}
                        >
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            style={{ width: '100%' }}
                          >
                            <Grid item xs>
                              <Typography variant="caption">
                                {route.label}
                              </Typography>
                              <Grid container direction="column" spacing={2}>
                                <Grid item>
                                  <Stack
                                    direction="row"
                                    spacing={0.5}
                                    padding={0}
                                  >
                                    {route.has_bypass_speed_limit && (
                                      <Typography
                                        color="error.main"
                                        variant="caption"
                                      >
                                        ⚠️
                                      </Typography>
                                    )}
                                    <Typography
                                      color="text.secondary"
                                      variant="caption"
                                    >
                                      {route.duration_in_minutes} minutos
                                    </Typography>
                                    <Box width={2} />
                                    <Typography
                                      color="text.secondary"
                                      variant="caption"
                                    >
                                      {route.speed_avg} km/h
                                    </Typography>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <DenseTable
                            {...route}
                            onPositionClick={handlePositionClick}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={9}>
        <CustomMaps
          mapContainerStyle={{
            height: `calc(70vh - 64px - ${isMobileSizePx})`,
            width: '100%',
          }}
          shouldCenterMap={false}
          // @ts-ignore
          setMapCallback={setMap}
          zoom={17}
        >
          {path.length > 1 && (
            <Polyline
              path={path}
              options={{
                strokeColor: DEFAULT_MAPS.icon.color.path,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                icons: [
                  {
                    icon: {
                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    },
                    offset: '100%',
                  },
                ],
              }}
            />
          )}
          {markers}
          {geofencesQuery.data?.map((geofence) => {
            // @ts-ignore
            const polygonCoordinates = geofence.geo_polygon.coordinates[0].map(
              (coordinate: number[]) => ({
                lat: coordinate[1],
                lng: coordinate[0],
              }),
            );

            const center2 = polygonCoordinates.reduce(
              (
                acc: { lat: number; lng: number },
                coord: { lat: number; lng: number },
              ) => ({
                lat: acc.lat + coord.lat / polygonCoordinates.length,
                lng: acc.lng + coord.lng / polygonCoordinates.length,
              }),
              { lat: 0, lng: 0 },
            );

            return (
              <React.Fragment key={geofence.geo_id}>
                <Polygon
                  paths={polygonCoordinates}
                  options={{
                    fillColor: geofence.geo_color,
                    strokeColor: geofence.geo_color,
                    strokeOpacity: 0.1,
                    strokeWeight: 0.5,
                    fillOpacity: 0.2,
                  }}
                />
                <MarkerF
                  position={center2}
                  label={{
                    text: geofence.geo_name,
                    color: geofence.geo_color,
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                  icon={{
                    path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                    scale: 0, // O ícone é invisível, apenas o texto será mostrado
                  }}
                />
              </React.Fragment>
            );
          })}

          {gseRealtime ? (
            <>
              {gseRealtime?.ignition ? (
                <MarkerF
                  animation={window.google.maps.Animation.DROP}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: DEFAULT_MAPS.icon.scale,
                    labelOrigin: new window.google.maps.Point(
                      DEFAULT_MAPS.icon.labelOrigin.x,
                      DEFAULT_MAPS.icon.labelOrigin.y,
                    ),
                    strokeColor: DEFAULT_MAPS.icon.color.ignition.on,
                  }}
                  key={`location-ignition-on-${gseRealtime.latitude}-${gseRealtime.longitude}`}
                  label={gseRealtime.name}
                  position={
                    new google.maps.LatLng(
                      Number(gseRealtime.latitude),
                      Number(gseRealtime.longitude),
                    )
                  }
                />
              ) : (
                <MarkerF
                  animation={window.google.maps.Animation.DROP}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: DEFAULT_MAPS.icon.scale,
                    size: new window.google.maps.Size(20, 20),
                    scaledSize: new window.google.maps.Size(20, 20),
                    labelOrigin: new window.google.maps.Point(0, 3),
                    strokeColor: DEFAULT_MAPS.icon.color.ignition.off,
                  }}
                  key={`location-ignition-off-${gseRealtime.latitude}-${gseRealtime.longitude}`}
                  label={gseRealtime.name}
                  position={
                    new google.maps.LatLng(
                      Number(gseRealtime.latitude),
                      Number(gseRealtime.longitude),
                    )
                  }
                />
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </CustomMaps>
      </Grid>
    </Grid>
  );
};

interface DenseTableProps {
  positions: IPositionsDto[];
  onPositionClick: (index: number) => void;
}

const DenseTable: React.FC<DenseTableProps> = ({
  positions,
  onPositionClick,
}) => (
  <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell padding="none">
            <Typography variant="caption">Data</Typography>
          </TableCell>
          <TableCell padding="none">
            <Typography variant="caption">Velocidade</Typography>
          </TableCell>
          <TableCell padding="none">
            <Typography variant="caption">Ver</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {positions.map((position, index) => (
          <TableRow
            key={`position-${index}`}
            style={{ cursor: 'pointer' }}
            sx={{
              backgroundColor: position.has_bypass_speed_limit
                ? 'error.light'
                : 'inherit',
            }}
            onClick={() => onPositionClick(index)}
          >
            <TableCell padding="none">
              <Typography variant="caption">
                {new Date(position.date).toLocaleString('pt-BR')}
              </Typography>
            </TableCell>
            <TableCell padding="none">
              <Typography variant="caption">{position.speed} km/h</Typography>
            </TableCell>
            <TableCell padding="none">
              <Typography variant="caption">
                <Eye style={{ fontSize: '.75rem' }} />
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
