import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/config/query';
import { useTouchwayApi } from 'src/services/touchway-api';
import { addDays, format, set, subDays } from 'date-fns';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  CardMedia,
  Stack,
  Dialog,
  Fab,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@material-ui/lab';
import { ptBR } from 'date-fns/locale';
import { AUTH_TOKEN } from 'src/services/axiosService';
import GeofenceMap from './GeofenceMap';
import { VehiclePerimeterLink } from './VehiclePerimeterLink';
import { OverviewDateRange2 } from './components/OverviewDateRange2';

interface VehicleGeofenceHistoryProps {
  vhc_id: string;
}

const VehicleGeofenceHistory: React.FC<VehicleGeofenceHistoryProps> = ({
  vhc_id,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    set(new Date(), { hours: 0, minutes: 0, seconds: 1, milliseconds: 0 }),
  );
  const [endDate, setEndDate] = useState<Date>(
    set(new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 0 }),
  );
  const touchwayApi = useTouchwayApi();

  const params = {
    vhc_id,
    start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { locale: ptBR }),
    end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { locale: ptBR }),
  };
  const historyQuery = useQuery({
    queryKey: queryKeys.vehicles.vehicleControllerListVehicleGeofences(params),
    queryFn: () =>
      touchwayApi.api.vehicles.vehicleControllerListVehicleGeofences(params),
  });

  const geofencesQuery = useQuery({
    queryKey: queryKeys.geofences.geofenceControllerFindAll(),
    queryFn: () => touchwayApi.api.geofences.geofenceControllerFindAll(),
  });

  const linkedGeofencesQuery = useQuery({
    queryKey: queryKeys.vehicles.vehicleControllerListLinkedGeofences({
      vhc_id,
    }),
    queryFn: () =>
      touchwayApi.api.vehicles.vehicleControllerListLinkedGeofences({
        vhc_id,
      }),
  });

  const downloadEndpoint = useMemo(() => {
    const base = `${process.env.REACT_APP_REPORT_URL}`;
    const { start, end } = params;
    return `${base}/perimetros/?start_date=${start}&end_date=${end}&vhc_id=${
      params.vhc_id
    }&token=${localStorage.getItem(AUTH_TOKEN)}`;
  }, [params]);

  return (
    <Box sx={{ pl: 0, pr: 2 }}>
      <Grid container spacing={2}>
        {/* Histórico Amigável */}
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack>
              <Typography variant="h5">Histórico de perímetros</Typography>
              <Typography variant="body2" color="textSecondary">
                Entradas e saídas no período de{' '}
                {format(startDate, 'dd/MM/yyyy')} até{' '}
                {format(endDate, 'dd/MM/yyyy')}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="flex-start"
              justifyContent="flex-end"
            >
              <OverviewDateRange2
                startDate={startDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                align="row"
              />
              <Button
                variant="outlined"
                color="primary"
                href={downloadEndpoint}
                target="_blank"
                rel="noopener noreferrer"
                size="small"
                disabled={
                  (historyQuery?.data?.history ?? []).length === 0 ||
                  historyQuery.isLoading
                }
              >
                Baixar relatório infrações
              </Button>
            </Stack>
          </Stack>
          <Timeline sx={{ ml: 0 }}>
            {(historyQuery?.data?.history ?? []).length === 0 && (
              <EmptyPlaceholder
                title="Nenhum dado encontrado"
                subtitle="Não há dados disponíveis para o período selecionado."
              />
            )}
            {(historyQuery?.data?.history ?? []).map((event, index) => (
              <TimelineItem key={event.vge_id}>
                <TimelineOppositeContent
                  sx={{ flex: 0.1, textAlign: 'left', pl: 0 }}
                >
                  <Typography variant="body2" color="textSecondary">
                    {new Date(event.vge_created_at).toLocaleTimeString('pt-BR')}{' '}
                    em{' '}
                    {new Date(event.vge_created_at).toLocaleDateString('pt-BR')}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    color={
                      event.vge_event_type === 'ENTRY' ? 'primary' : 'secondary'
                    }
                  />
                  {index < (historyQuery?.data?.history ?? []).length - 1 && (
                    <TimelineConnector />
                  )}
                </TimelineSeparator>
                <TimelineContent sx={{ flex: 1, pr: 0 }}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <GeofenceMap
                        geofence={event.geofence}
                        point={{
                          lat: event.vge_latitude,
                          lng: event.vge_longitude,
                        }}
                      />
                    </Box>
                    <CardContent sx={{ flex: 1 }}>
                      <Typography variant="subtitle1">
                        {event.vge_event_type === 'ENTRY'
                          ? 'Entrou em'
                          : 'Saiu de'}{' '}
                        <strong>{event?.geofence?.geo_name}</strong>
                      </Typography>
                      <Typography variant="body2">
                        Coordenadas: ({event.vge_latitude},{' '}
                        {event.vge_longitude})
                      </Typography>
                    </CardContent>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Grid>

        {/* Relatório Estruturado */}
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Geofences cadastradas
          </Typography>
          <Grid container spacing={2}>
            {(linkedGeofencesQuery.data ?? []).length === 0 && (
              <EmptyPlaceholder
                title="Nenhum dado encontrado"
                subtitle="Não há dados disponíveis para o período selecionado."
              />
            )}
            {(linkedGeofencesQuery.data ?? []).map((geofence) => {
              if (!geofence) {
                return null;
              }
              const found = geofencesQuery.data?.find(
                (geo) => geo.geo_id === geofence.geo_id,
              );

              return (
                <Grid item xs={12} sm={6} md={4} key={geofence.geo_id}>
                  <Card>
                    <CardMedia>
                      {found && <GeofenceMap geofence={found} />}
                    </CardMedia>
                    <CardContent>
                      <Typography variant="subtitle1" gutterBottom>
                        <strong>{geofence.geo_name}</strong> (Cor:{' '}
                      </Typography>
                      {/* <Typography variant="body2">
                        Total de Entradas: {geofence.summary.totalEntries}
                      </Typography>
                      <Typography variant="body2">
                        Total de Saídas: {geofence.summary.totalExits}
                      </Typography> */}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Vincular Geofences</DialogTitle>
        <DialogContent>
          <VehiclePerimeterLink vhc_id={vhc_id} setOpenDialog={setOpenDialog} />
        </DialogContent>
      </Dialog>

      <Fab
        color="primary"
        variant="extended"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={() => setOpenDialog(true)}
      >
        Vincular Geofences
      </Fab>
    </Box>
  );
};

export default VehicleGeofenceHistory;
