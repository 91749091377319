import * as React from 'react';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Stack,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { IGeofence } from 'src/services/touchway-base-api';
import { FleetVehicles } from './fleet-vehicles';
import { ICompleteMapVehicle, Vehicle } from './types';

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    scrollbarWidth: 'thin', // Para Firefox
    scrollbarColor: `${theme.palette.action.selected} transparent`, // Para Firefox

    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.action.selected,
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
  },
}));

export interface SidebarProps {
  currentVehicleId?: string;
  currentGeofenceId: string | undefined;
  onClose?: () => void;
  onVehicleDeselect: () => void;
  onVehicleSelect: (vehicleId: string) => void;
  onGeofenceSelect: (geofenceId: string) => void;
  onGeofenceDeselect?: () => void;
  open?: boolean;
  geofences: IGeofence[];
  vehicles: ICompleteMapVehicle[];
}

export function Sidebar({
  currentVehicleId,
  currentGeofenceId,
  onClose,
  onGeofenceSelect,
  onGeofenceDeselect,
  onVehicleDeselect,
  onVehicleSelect,
  open,
  vehicles,
  geofences,
}: SidebarProps): JSX.Element {
  const mdUp = true;

  const content = (
    <SidebarContent
      currentVehicleId={currentVehicleId}
      currentGeofenceId={currentGeofenceId}
      onClose={onClose}
      onVehicleDeselect={onVehicleDeselect}
      onVehicleSelect={onVehicleSelect}
      onGeofenceSelect={onGeofenceSelect}
      onGeofenceDeselect={onGeofenceDeselect}
      vehicles={vehicles}
      geofences={geofences}
    />
  );

  if (mdUp) {
    return (
      <Box
        sx={{
          borderRight: '1px solid var(--mui-palette-divider)',
          display: { xs: 'none', md: 'block' },
          flex: '0 0 auto',
          width: '320px',
        }}
      >
        {content}
      </Box>
    );
  }

  return (
    <Drawer
      PaperProps={{ sx: { maxWidth: '100%', width: '320px' } }}
      onClose={onClose}
      open={open}
    >
      {content}
    </Drawer>
  );
}

export interface SidebarContentProps {
  currentVehicleId?: string;
  currentGeofenceId: string | undefined;
  onClose?: () => void;
  onVehicleDeselect: () => void;
  onVehicleSelect: (vehicleId: string) => void;
  onGeofenceSelect: (geofenceId: string) => void;
  onGeofenceDeselect?: () => void;
  vehicles: Vehicle[];
  geofences: IGeofence[];
  children?: React.ReactNode;
}

function SidebarContent({
  currentVehicleId,
  currentGeofenceId,
  onClose,
  onVehicleDeselect,
  onVehicleSelect,
  onGeofenceSelect,
  onGeofenceDeselect,
  vehicles,
  geofences,
  children,
}: SidebarContentProps): JSX.Element {
  const theme = useTheme();
  const VehiclesStack = () => (
    <>
      <Stack spacing={1} sx={{ flex: '0 0 auto', p: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
        >
          <Typography variant="h6">Frota</Typography>
          <Typography variant="caption">
            {vehicles.length} veículo(s)
          </Typography>
          <IconButton onClick={onClose} sx={{ display: { md: 'none' } }}>
            <Close />
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
      <Box
        sx={{ flex: '1 1 auto', overflowY: 'auto' }}
        className={useStyles().scrollContainer}
      >
        {children}
        <FleetVehicles
          currentVehicleId={currentVehicleId}
          onGeofenceDeSelect={onGeofenceDeselect}
          onVehicleDeselect={onVehicleDeselect}
          onVehicleSelect={onVehicleSelect}
          vehicles={vehicles}
        />
      </Box>
    </>
  );

  const GeofencesStack = () => {
    let bgColor =
      currentGeofenceId === undefined ? 'action.selected' : 'transparent';

    if (currentVehicleId !== undefined) {
      bgColor = 'transparent';
    }

    return (
      <Stack
        spacing={1}
        sx={{ flex: '0 0 auto' }}
        className={useStyles().scrollContainer}
      >
        <Divider />
        <Stack
          direction="row"
          spacing={2}
          paddingX={2}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
        >
          <Typography variant="h6">Perímetros</Typography>
          <Typography variant="caption">
            {geofences.length} perímetro(s)
          </Typography>
          <IconButton onClick={onClose} sx={{ display: { md: 'none' } }}>
            <Close />
          </IconButton>
        </Stack>
        <Divider />
        <Box sx={{ maxHeight: 200, overflowY: 'auto', p: 1 }}>
          <List dense disablePadding>
            <ListItem
              button
              onClick={() => {
                onVehicleDeselect?.();
                onGeofenceDeselect?.();
              }}
              key="all"
              sx={{
                cursor: 'pointer',
                backgroundColor: bgColor,
              }}
            >
              Todos os perímetros
            </ListItem>

            {geofences.map((geofence) => {
              let selected = currentGeofenceId === geofence.geo_id;
              if (currentVehicleId !== undefined) {
                selected = false;
              }
              return (
                <ListItem
                  button
                  onClick={() => {
                    onVehicleDeselect?.();
                    onGeofenceSelect?.(geofence.geo_id);
                  }}
                  key={geofence.geo_id}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: selected
                      ? 'action.selected'
                      : 'transparent',
                  }}
                >
                  {geofence.geo_name}
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Stack>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <VehiclesStack />
      <GeofencesStack />
    </Box>
  );
}
