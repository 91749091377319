import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
// @ts-ignore
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
  height?: number;
  color?: 'white' | 'black';
}

const Logo: FC<LogoProps> = ({
  color = 'black',
  height = 40,
  sx,
  ...other
}) => (
  <img
    width="auto"
    height={height}
    src={color === 'white' ? '/static/logo-white.png' : '/static/logo.png'}
    alt="Logo"
    {...other}
  />
);

export default Logo;
